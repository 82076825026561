import "codemirror/lib/codemirror.css"
import CodeMirror from "codemirror"
import {Controller} from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    this.instance = CodeMirror.fromTextArea(this.element, {
      lineNumbers: true,
      mode: "text/html",
      tabSize: 2,
      theme: "default"
    })
    this.instance.setSize("100%", null)

    this.instance.on("change", (cm) => {
      cm.save()
    })
  }
}
