// Dont load in test env because API makers error-logger already load source maps and loading twice fucks it up
if (window.peakflowErrors && window.RAILS_ENV != "test") {
  window.peakflowErrors.loadSourceMapForScriptTags((scriptTag) => {
    const src = scriptTag.getAttribute("src")
    const type = scriptTag.getAttribute("type")

    if (src && src.includes("/packs/") && (type == "text/javascript" || !type)) {
      const url = window.peakflowErrors.loadUrl(src)
      const originalUrl = `${url.origin}${url.pathname}`

      return originalUrl
    }

    return false
  })
}
